.ant-tabs-content {
  height: 100%;
}

.ant-tabs-tabpane {
  height: 100%;
}

.ant-tab-with-overflow {
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
}

.tabs-without-padding {
  .ant-tabs-tab {
    padding: 0 !important;
    margin: 0 !important;
  }

  .ant-tabs-tabpane {
    padding-left: 24px !important;
  }
}
